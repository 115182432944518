<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Change staff comment</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label p-input-icon-right" >
          <i class="ti-comment-alt"></i>
          <Textarea :class="{'p-invalid' : submitted && !staffComment}" id="order_staff_comment_modal" v-model.trim="staffComment" rows="5" autoResize autocomplete="off"/>
          <label for="order_staff_comment_modal">Staff comment<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !staffComment">{{ $t('Required field') }}</small>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="updateComment" />
    </template>
  </Modal>
</template>

<script>
// import httpMixins from "@/mixins/httpMixins";
import httpClient from "@/services/http.services";
import formatMixins from "@/mixins/formatMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins, formatMixins ],
  name: 'OrderStaffCommentModal',
  emits: ['close', 'update-staff-comment'],
  props: {
    visible: Boolean,
    item: Object,
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.staffComment = null
      }
    },
    item(value) {
      if (!value) return false
      this.orderId = value.orderId
      this.staffComment = value.comment
    }
  },
  data() {
    return {
      submitted: false,
      orderId: null,
      staffComment: null,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async updateComment() {
      this.submitted = true
      if (!this.staffComment) {
        return false
      }

      const obj = {
        order_id: this.orderId,
        staff_comment: this.staffComment,
      }

      try {
        const { status, data } = await httpClient.post('order/update-staff-comment', obj)
        if (status === 200 && data?.success) {
          this.close()
          this.$emit('update-staff-comment', obj)
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      }
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '90%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '550px', marginTop: '25px'}
      } else {
        return {position: 'relative', top: '-10vh', width: '600px', marginTop: '50px'}
      }
    },
  }
}
</script>