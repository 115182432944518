<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div>
              <div style="font-size: 1.4em; color: inherit">{{ $t('Order') }}: {{ $route.params.number ?? '' }}</div>
            </div>

          </template>
<!--          <template v-slot:right v-if="orderData">-->
          <template #end v-if="orderData">
            <Button class="p-button-raised p-button-success" icon="pi pi-comments" label="Edit comment" :disabled="(disableEditButton && isItemUpdated(item.id)) || dataTableIsLoading" @click="editStaffComment" />
          </template>
        </Toolbar>
        <Spinner v-if="isLoading"></Spinner>
<!--        <div v-else-if="!isLoading">{{ orderData }}</div>-->

        <div v-else-if="!isLoading && orderData" class="p-grid">
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <fieldset class="p-fieldset">
              <legend class="p-fieldset-legend p-p-2">Files</legend>
              <div v-if="orderData?.files">
                <div v-for="file of orderData.files" :key="file.id">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                      <a :href="settings.apiEndPoint + settings.orderFilesFolder + file.file_path" target="_blank">
<!--                        <div style="overflow: hidden; text-overflow: ellipsis;">-->
                          {{ file.source_name }}
                      </a>
                      <div class="p-mt-1" style="font-size: 0.9rem">
                        <span v-if="file.size">{{ $t('Size') }}: {{ formatDecimal(file.size / 1048576) }}mb</span>
                        <span v-if="file.created_at">, {{ $t('Created') }}: {{ formatDate(file.created_at) }}</span>
                        <span v-if="file.createdBy">,
                          <span v-if="file.createdBy.type === constants.userTypes.legal_entity && file.createdBy.company_name">{{ file.createdBy.company_name }}</span>
                          <span v-else>{{ file.createdBy.first_name }} {{ file.createdBy.last_name }}</span>
                        </span>
                      </div>
                    </div>
                    <Button @click="deleteFileOnClick(file)" icon="ti-close" class="p-button-text"></Button>
                  </div>
                  <hr class="p-mt-2 p-mb-2">
                </div>
              </div>
              <Button @click="addFiles" class="p-button-text p-mt-1 p-mr-3" icon="pi pi-plus" style="width: auto" :label="$t('Add')"></Button>
            </fieldset>
          </div>
        </div>
        <div v-else class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
			</div>
<!--      Second part-->
      <div v-if="!isLoading && orderData" class="card p-mt-4">
        <div class="p-grid">
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <Panel header="Order details">
              <table>
                <tbody style="text-align: left">
                  <tr>
                    <th class="p-p-1">Name:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3 p-d-flex p-ai-center p-flex-wrap">
                        <span v-if="orderData.name">{{ orderData.name }}</span>
                        <i v-else class="ti-minus"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">Time:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                        <span v-if="orderData.time">{{ orderData.time }} {{ $t('h') }}.</span>
                        <i v-else class="ti-minus"></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">Status:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3 p-d-flex p-ai-center">
                        <OrderStateButton :item="orderData" :canChangeState="false"/>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="orderData.called_at || orderData.called_by">
                    <th class="p-p-1">Called:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                      <span>
                        <span v-if="orderData.called_at">{{ formatDate(orderData.called_at) }}, </span>
                        <span v-if="orderData.calledBy">
<!--                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}<span> ({{ orderData.creator.first_name }} {{ orderData.creator.last_name }})</span></span>-->
                          <span>{{ orderData.calledBy.first_name }} {{ orderData.calledBy.last_name }}</span>
                        </span>
                      </span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="orderData.state === 12">
                    <th class="p-p-1">Cancellation reason:</th>
                    <td class="p-p-1">
                      <div class="p-ml-3">
                        <span v-if="orderData.cancellation_reason">{{ orderData.cancellation_reason }}</span>
                        <i v-else class="ti-minus"></i>
                        <span>
                          <a @click.prevent="editCancellationReason" class="p-link p-p-1 p-ml-2">
                            <i class="ti-pencil"></i>
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">{{ $t('Start') }}:</th>
                    <td class="p-p-1 p-d-flex p-flex-wrap">
                      <span class="p-ml-3">{{ formatDate(orderData.start) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-p-1">{{ $t('Car') }}:</th>
                    <td class="p-p-1">
                      <div v-if="orderData.car" class="p-ml-3 p-d-flex p-flex-wrap p-ai-center">
                        <span><span v-if="orderData.car.make">{{ orderData.car.make.name }}</span> <span v-if="orderData.car.model">{{ orderData.car.model.name }}</span><span v-if="orderData.car.plate_number">, {{ orderData.car.plate_number }}</span></span>
                      </div>
                      <i v-else class="ti-minus p-ml-3"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div class="p-col-12 p-sm-12 p-md-6 p-lg-4">
            <Panel header="Common data">
              <table>
                <tbody style="text-align: left">
                <tr>
                  <th class="p-p-1">{{ $t('Created') }}:</th>
                  <td class="p-p-1">
                    <div class="p-ml-3">
                      <span :class="{'order-created-by-customer':orderData.is_created_by_customer}">
                        <span v-if="orderData.created_at">{{ formatDate(orderData.created_at) }}, </span>
                        <span v-if="orderData.creator">
<!--                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}<span> ({{ orderData.creator.first_name }} {{ orderData.creator.last_name }})</span></span>-->
                          <span v-if="orderData.creator.type === constants.userTypes.legal_entity && orderData.creator.company_name">{{ orderData.creator.company_name }}</span>
                          <span v-else>{{ orderData.creator.first_name }} {{ orderData.creator.last_name }}</span>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Archived:</th>
                  <td class="p-p-1">
                    <div v-if="orderData.is_archived" class="p-ml-3">
                      <span v-if="orderData.archived_at">{{ formatDate(orderData.archived_at) }}, </span>
                      <span v-if="orderData.archivedBy">
<!--                        <span v-if="orderData.archivedBy.type === constants.userTypes.legal_entity && orderData.archivedBy.company_name">{{ orderData.archivedBy.company_name }}<span> ({{ orderData.archivedBy.first_name }} {{ orderData.archivedBy.last_name }})</span></span>-->
                        <span v-if="orderData.archivedBy.type === constants.userTypes.legal_entity && orderData.archivedBy.company_name">{{ orderData.archivedBy.company_name }}</span>
                        <span v-else>{{ orderData.archivedBy.first_name }} {{ orderData.archivedBy.last_name }}</span>
                      </span>
                    </div>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Responsible:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3 p-d-flex p-flex-wrap" v-if="orderData.responsible">
<!--                      <router-link :to="{ path: `/employees/${orderData.responsible.id}` }" target="_blank">-->
                        {{ orderData.responsible.first_name }} {{ orderData.responsible.last_name }}
<!--                      </router-link>-->
                    </span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Masters:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3 p-d-flex p-flex-wrap" v-if="orderData.masters?.length">
                      <span v-for="(master, index) of orderData.masters" :key="master.id">
<!--                        <router-link :to="{ path: `/employees/${master.id}` }" target="_blank">-->
                        <span>{{ master.user.first_name }} {{ master.user.last_name }}</span>
                        <span v-if="orderData.masters.length > 1 && (index !== orderData.masters.length - 1)">,&nbsp;</span>
<!--                        </router-link>-->
                      </span>
                    </span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                <tr>
                  <th class="p-p-1">Mileage:</th>
                  <td class="p-p-1">
                    <span class="p-ml-3" v-if="orderData.mileage">{{ formatMileage(orderData.mileage) }} {{ $t(settings.measurements.mileage) }}</span>
                    <i v-else class="ti-minus p-ml-3"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </Panel>
          </div>
        </div>
        <div class="p-mt-4"></div>
        <PositionsTable :item="orderData" :showDeletedPositions="showDeletedPositions" :showFinancialData="false" :isSpoilerTable="false" @update-items="updateItems"/>
      </div>

      <FilesModal :visible="filesModal" @close="closeFilesModal" :orderId="orderData?.id" @update-item="updateItem"/>

      <ConfirmDeleteModal :visible="confirmDeleteFileModal"
                          :data="fileToDelete.source_name"
                          @delete-item="deleteFile"
                          @close="closeConfirmDeleteFileModal"/>

      <OrderStaffCommentModal :visible="orderStaffCommentModal"
                              @update-staff-comment="updateOrderStaffComment"
                              @close="closeOrderStaffCommentModal"
                              :item="staffCommentData"/>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import FilesModal from "@/components/FilesModal";
import webSocketMixins from "@/mixins/webSocketMixins";
import OrderStaffCommentModal from "@/pages/orders/components/OrderStaffCommentModal";

export default {
  components: { OrderStateButton,PositionsTable,FilesModal,OrderStaffCommentModal },
	mixins: [ formatMixins, httpMixins, datatableMixins, webSocketMixins ],
  data() {
		return {
      staffCommentData: {},
      fileToDelete: {},
      confirmDeleteFileModal: false,
      filesModal: false,
      orderStaffCommentModal: false,
      disableEditButton: false,
      orderMasters: [],
      servicePositions: null,
      orderData: null,
      isLoading: true,
      deletedPositions: false,
      showDeletedPositions: false,
      settings,
      constants
		}
	},
  watch: {
    '$route'() {
      if (this.$route.path === '/login' || this.$route.path !== '/master-orders/' + this.$route.params.number) {
        return false
      }
      if (this.$route.params.number) {
        this.getOrderData()
      }
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.filesModal) {
          this.closeFilesModal()
        }
        if (this.confirmDeleteFileModal) {
          this.closeConfirmDeleteFileModal()
        }
        if (this.orderStaffCommentModal) {
          this.closeOrderStaffCommentModal()
        }
      }
    },
  },
  mounted() {
    if (!this.$route.params.number) return false
    this.getOrderData()
	},
  methods: {
    // updateItem(id, sendSocket = false) { // Don't delete
    updateItem(id) {
      const orderId = this.orderData?.id || id
      if (!orderId) return

      this.disableEditButton = true
      if (this.$route.params.number) {
        this.getOrderData(false)

        // if (sendSocket) {  // Don't delete
        //   this.sendUpdateOrderWebSocket(orderId)
        // }
      }
    },
    editStaffComment() {
      if (!this.orderData) return
      this.staffCommentData = {
        orderId: this.orderData.id,
        comment: this.orderData.staff_comment,
      }
      this.orderStaffCommentModal = true
      this.$store.commit('openRequiredAppLayer')
    },
    updateOrderStaffComment(obj) {
      if (!obj) return false
      this.orderData.staff_comment = obj.staff_comment
      if (!this.orderData.comments_history) this.orderData.comments_history = []

      this.orderData.comments_history.push({
        type: 2,
        comment: obj.staff_comment,
        created_at: Date.now() / 1000,
        creator: {
          first_name: this.$store.state.user?.first_name,
          last_name: this.$store.state.user?.last_name
        },
      })
    },
    closeOrderStaffCommentModal() {
      this.staffCommentData = {}
      this.orderStaffCommentModal = false
      this.$store.commit('closeRequiredAppLayer')
    },
    async deleteFile() {
      const fileId = this.fileToDelete?.id
      try {
        const { status } = await httpClient.post(`order/delete-file`, { fileId })
        if (status === 204) {
          this.closeConfirmDeleteFileModal()
          this.orderData.files = this.orderData.files.filter(f => f.id !== fileId)
        }
      } catch(err) {
        this.showError(err)
      }
    },
    deleteFileOnClick(file) {
      this.fileToDelete = file
      this.confirmDeleteFileModal = true
      // this.$store.commit('toggleFirstLayer', true)
      this.$store.commit('openRequiredAppLayer')
    },
    closeConfirmDeleteFileModal() {
      this.confirmDeleteFileModal = false
      // this.$store.commit('toggleFirstLayer', false)
      this.$store.commit('closeRequiredAppLayer')
      this.fileToDelete = {}
    },
    addFiles() {
      this.filesModal = true
      // this.$store.commit('toggleFirstLayer', true)
      this.$store.commit('openRequiredAppLayer')
    },
    closeFilesModal() {
      this.filesModal = false
      this.$store.commit('closeRequiredAppLayer')
      // this.$store.commit('toggleFirstLayer', false)
    },
    async getOrderData(showSpinner = true) {
      this.isLoading = showSpinner
      try {
        const { data, status } = await httpClient.post('order/get-order-by-number?expand=responsible,creator,archivedBy,positions,masters.employee,car,area,state_history,comments_history,recommendation_history,files.createdBy', { orderNumber: this.$route.params.number })
        if (status === 200 && data?.success && data.order) {
          const order = data.order
          this.orderData = order
          this.orderData.selectedCustomer = order.customer ? order.customer : null
          this.orderData.selectedCar = order.car ? order.car : null
        } else {
          this.orderData = null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        // this.$store.commit('changeUpdatedOrderId', null)
        this.isLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    noActivePositionInOrder() {
      return !(this.orderData?.positions?.find(p => p.status))
    },
  }
}
</script>